import '../Styles/Nav.css'
import {easeIn, easeOut, motion} from 'framer-motion'
import { useState } from 'react'

import EliteTech from '../Images/NavEliteLogoOrangeImage.png'

import {NavLink} from 'react-router-dom'

function Nav() {

  const [notOpened, setNotOpened] = useState(true)

  const animateNavMenu = () => {setNotOpened(!notOpened)}

  return (
    <div className="nav">
      
      <motion.nav className='navbar'>
        <img className='company-logo' src={EliteTech} alt='company logo'></img>

        <motion.div className='desktop-size'>
          <motion.ul className='desktop-list'>
            <NavLink to='/'><motion.li>Home</motion.li></NavLink>
            <NavLink to='/computer-services'><motion.li>Services</motion.li></NavLink>
            <NavLink to='/contact-us'><motion.li>Contact Us</motion.li></NavLink>
            <NavLink to='/about-us'><motion.li>About Us</motion.li></NavLink>
          </motion.ul>
        </motion.div>

        <motion.div className='mobile-size'>
          <motion.svg onClick={animateNavMenu} className='nav-btn hamburger-btn' width='10px' height='20px' alt='Hamburger menu'>
            <path d="M5 7h14c.6 0 1-.4 1-1s-.4-1-1-1H5c-.6 0-1 .4-1 1s.4 1 1 1zm0 6h14c.6 0 1-.4 1-1s-.4-1-1-1H5c-.6 0-1 .4-1 1s.4 1 1 1zm0 6h14c.6 0 1-.4 1-1s-.4-1-1-1H5c-.6 0-1 .4-1 1s.4 1 1 1z"/>
          </motion.svg>
        </motion.div>
        
        <motion.div className='nav-menu' animate={{transition: notOpened ? easeIn : easeOut,
           x: notOpened ? 0 : '-52vw',
           display: notOpened ? 'none' : 'block'}}> 
          <motion.div className='close-btn-container'>
            <div className='rubbish'>y</div>
            <motion.svg onClick={animateNavMenu} className='nav-btn close-btn' alt='Close menu' xmlns="http://www.w3.org/2000/svg" width="18" height="18">
              <path fill="none" fillRule="evenodd" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 1 1 13M1 1l12 12"/>
            </motion.svg>
          </motion.div>
          <motion.ul className='mobile-list'>
          <NavLink to='/'><motion.li>Home</motion.li></NavLink>
            <NavLink to='/computer-services'><motion.li>Services</motion.li></NavLink>
            <NavLink to='/contact-us'><motion.li>Contact Us</motion.li></NavLink>
            <NavLink to='/about-us'><motion.li>About Us</motion.li></NavLink>
          </motion.ul>
        </motion.div>
      </motion.nav>
      
    </div>
  );
}

export default Nav;
