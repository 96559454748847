import React from 'react'

// Styles -- BEGIN

import '../../Styles/ComputerServices.css'
import '../../Styles/Nav.css'

// Styles -- END

import ComputerServicesWomanHeroImage from '../../Images/ComputerServicesWomanHeroImage.png'
import ComputerServicesMaintenanceBannerImage from '../../Images/ComputerServicesMaintenanceBannerImage.png' 

// Components -- BEGIN

import Nav from '../Nav'
import Footer from '../Footer'
import { Link } from 'react-router-dom'

// Componets -- END

function ComputerServices() {
  return (
    <div className='computer-services'>
      <Nav />

      <div className='computer-services-content'>
        <div className='computer-services-hero hero container'>
          <div className='left-side'>
            <span>Your trusted and reliable IT partner.</span>
          </div>

          <div className='right-side'>
            <img className='hero-image' src={ComputerServicesWomanHeroImage} alt=''></img>
          </div>
        </div>
        <h1>Maintenance</h1>

        <div className='banner'>
          <img src={ComputerServicesMaintenanceBannerImage} alt=''></img>
        </div>

        <div className='maintenance-subcategories container'>
          <div className='data-centers-and-servers'>
            <h1>Data Centers and Servers</h1>
            <div>
              Data centers and servers are the heart and soul of any IT system and it’s vital to keep them in good condition.
               Problems with these systems can bring the entire business to a standstill so they need to be fixed quickly. 
               Our experts will perform preventive and corrective maintenance and repairs that help your system work well at all times.
              We offer both onsite and remote support so you can call us whenever you have a problem. 
            </div>
          </div>
          <div className='Printers and Peripherals'>
            <h1>Printers and Peripherals</h1>
            <div>Printers, scanners, copiers, faxes, and other such peripherals are prone to problems as well and many manufacturers
               don’t offer on-site warranties for them. Our maintenance experts will make sure these peripherals are in good shape and 
               all issues are fixed promptly with a preventive and corrective procedures. This ensures your business operations aren’t 
               disrupted for too long. 
            </div>
          </div>
          <div className='laptops-and-workstations'>
            <h1>Laptops and Workstations</h1>
            <div>Most employees are careful when they use company equipment but accidents can happen. We’ve found that laptops and 
              desktops are most susceptible to damage and failures in an office environment so an out of warranty service is extremely 
              useful and will ensure they function well. 
            </div>
          </div>

          <div className='want-to-know-more'>
          If you want to know more about our services or have any questions, feel free to get in touch with us at 
          Elite Tech ICT Solutions. You can <Link to={'/contact-us'}><span className='contact-us'>contact us</span></Link>  through the contact form or call the relevant numbers listed on the contact us page.
          </div>

        </div>    
      </div>
        
      <Footer />
    </div>
  )
}

export default ComputerServices