import React from 'react'
import '../Styles/Footer.css'
import FooterWhiteLogo from '../Images/FooterEliteLogoWhiteImage.png'

function Footer() {
  return (
    <div className='footer'>
      <div className='background'>
        <div className='content container'>
          <div className='image-side'>
            <img className='footer-logo' src={FooterWhiteLogo} alt='Company logo'></img>
          </div>

          <div className='text-side'>
            <ul className='computer-services-list'>
              <h3>Computer Services </h3>
              <li>Computer Diagnostics </li>
              <li>Virus & Malware Removal </li>
              <li>Computer Cleaning & Tune-ups </li>
              <li>Hardware Replacement</li>
              <li>Hard Drive & Motherboard Repair</li>
              <li>Data Recovery & transfer</li>
              <li>Software Help </li>
              <li>Recovery Media Creation </li>
              <li>Data Backup & Transfer</li>
            </ul>
            <ul className='printing-services-list'>
              <h3>Packaging and Printing</h3>
              <li>Large Format Printing</li>
              <li>Concept Design</li>
              <li>Quality</li>
              <li>Innovation</li>
              <li>Design and Creativity</li>
              <li>Nationwide Installation</li>
              <li>Integrated POS</li>
              <li>Picking and Packing</li>
            </ul>
          </div>

        </div>
        <div className='footer-address container'>
        Address: Ayer Tena, Pinel Building office number 11 <br></br>
        Contact Number: +2519 63 32 03 63 <br></br>
        Contact Email: info@eliteblitz.net <br></br>
        </div>
      </div>
    </div>
  )
}

export default Footer