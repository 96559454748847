import React from 'react'
import ComputerServicesImage from '../Images/HomeComputerServicesImage.png'
import AdvertImage from '../Images/HomeAdvertImage.png'
import PrintingImage from '../Images/HomePackagingAndPrintingImage.png'
import { Link } from 'react-router-dom'

function Services() {
  return (
    <div className='services'>
      <h1>Our Services</h1>

      <div id='maintenance-services' className='computer-service-category categories'>
        <div className='background'>
          <div className='content'>
            <div className='image-side'>
              <img className='computer-services-image services-image' src={ComputerServicesImage} alt='Computer Services'></img>
            </div>

            <div className='text-side'>
              <h2>Computer Services</h2>
              <div className='body'>
                At Elite Tech ICT Solutions we offer excellent out of warranty maintenance services that would help keep your IT equipment
                optimized for performance. Most computer hardware equipment comes with a fixed warranty period of 1 or 2 years 
                but they have many limitations. These warranties are often not as comprehensive as one would like, so you end up 
               <Link to={'/computer-services'}><span className='read-more'> read more...</span></Link>
              </div>
            </div>
          </div>
          
        </div>
      </div>

      <div id='printing-services' className='printing-service-category categories'>
        <div className='background'>
          <div className='content'>
            <div className='text-side'>
              <h2>Packaging and Printing</h2>
              <div className='body'>
                We see the challenges faced by brand owners and agencies to deliver ‘the message’ of their brand with maximum impact. 
                We also know exactly how large format printing fits into the equation and  how to augment communications to maximize your ROI. 
                Wide format printing can be effective across a range of retail stores and brands using indoor, online and outdoor solutions 
                to reach a large audience. Our team can work with brands from concept right through to installation to ensure a straight forward, 
                comprehensive service. <span className='read-more'> read more...</span> 
              </div>
            </div>
      
            <div className='image-side'>
              <img className='printing-services-image services-image' src={PrintingImage} alt='Computer Services'></img>
            </div>
          </div>
          
        </div>
      </div>

      <div id='advert-services' className='advert-service-category categories'>
        <div className='background'>
          <div className='content'>
            <div className='image-side'>
              <img className='advert-services-image services-image' src={AdvertImage} alt='Computer Services'></img>
            </div>

            <div className='text-side'>
              <h2>Advertising</h2>
              <div className='body'>
              At ICT Services, we offer excellent out of warranty maintenance services that would help keep your IT equipment optimized for performance. 
              Most computer hardware equipment comes with a fixed warranty period of 1 or 2 years but they have many limitations. These warranties are
              often not as comprehensive as one would like, so you end up<span className='read-more'> read more... </span> 
              </div>
            </div>
          </div>
          
        </div>
      </div>
     

    </div>
  )
}

export default Services