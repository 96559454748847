import React from 'react'

import ContactUsImage from '../Images/HomeContactUsImage.png'

import '../Styles/ContactUs.css'

function ContactUs() {
  return (
    <div id='contact-us' className='contact-us container'>
      <h1>Contact Us</h1>
      <div className='contact-us-content'>
        <div className='form-side'>
          <div className='contact-us-detail'>Would like to order or get intouch? You can fill in the form below or call us on +2519 63 32 03 63
            to speak to one of our represenatitives.
          </div>
          <input className='email form-input' placeholder='Email'></input>
          <textarea className='comment form-input' placeholder='Comment'></textarea>
          <div className='contact-us-submit'>
            <button className='contact-us-submit-btn'>Submit</button>
          </div>
        </div>

        <div className='image-side'>
          <img className='contact-us-image' src={ContactUsImage} alt=''></img>
        </div>
        
      </div>
      
    </div>
  )
}

export default ContactUs