import React from 'react';

// Styles -- START
import './App.css';
import './Styles/Hero.css'
import './Styles/Services.css'
// Styles -- END

// Components -- START

import Home from './Components/Home/Home'
import ComputerServices from './Components/Services/ComputerServices';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ContactUs from './Components/ContactUs/ContactUs';
// Components -- END

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route path="/computer-services" element={<ComputerServices />}></Route>
          <Route path='/contact-us' element={<ContactUs />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
