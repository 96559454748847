import React from 'react'
import Hero from '../Hero';
import Nav from '../Nav';
import Services from '../Services';
import Footer from '../Footer';
import ContactUs from '../ContactUs';

function Home() {
  return (
    <div className='home'>
      <Nav />
      <Hero />
      <Services />
      <ContactUs />
      <Footer />
    </div>
  )
}

export default Home