import React from 'react'
import HeroImage from '../Images/HomeHeroImage.png'

function Hero() {
  return (
    <div className='hero container'>
      <div className='left-side'>
        <span><span className='positive'>Positive</span> impact on your business.</span>
      </div>

      <div className='right-side'>
        <img className='hero-image' src={HeroImage} alt=''></img>
      </div>
    </div>
  )
}

export default Hero